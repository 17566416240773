<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="data?.newsletter_title" class="relative flex aspect-[1920/750] w-full items-center">
    <nuxt-img
      provider="cloudinary"
      :src="data?.newsletter_image[0]"
      alt="newsletter"
      class="absolute z-0 hidden h-full w-full object-cover object-center md:block"
      loading="lazy"
      sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
      fit="fill"
      width="1920"
      height="750"
    />
    <nuxt-img
      provider="cloudinary"
      :src="data?.newsletter_image[0]"
      alt="newsletter"
      class="absolute z-0 h-full w-full object-cover object-right-top md:hidden"
      loading="lazy"
      sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
      fit="fill"
      width="600"
      height="1000"
    />
    <Container>
      <div class="relative z-40 mb-52 mt-12 max-w-screen-md lg:my-56">
        <div class="max-w-[75%] lg:max-w-screen-sm">
          <div class="mb-1.5 font-serif text-lg font-normal uppercase leading-snug text-blue">
            {{ $t('newsletter') }}
          </div>
          <div
            v-if="data?.newsletter_title"
            class="mb-6 max-w-[90%] font-serif text-4xl font-bold text-white lg:mb-5 lg:max-w-full lg:text-5xl lg:leading-snug"
          >
            {{ data.newsletter_title }}
          </div>
          <div
            v-if="data?.newsletter_copy?.value"
            class="font-sans text-base font-normal text-white lg:text-lg lg:leading-snug"
            v-html="data.newsletter_copy.value"
          ></div>
        </div>
        <div class="mt-36 lg:mt-16">
          <!-- Begin Mailchimp Signup Form -->

          <div id="mc_embed_signup_custom">
            <form
              id="mc-embedded-subscribe-form"
              action="https://djurensratt.us20.list-manage.com/subscribe/post?u=075aef938fc7213aacd1f4476&amp;id=2576ca066f&amp;f_id=00cac2e1f0"
              method="post"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <div id="mc_embed_signup_scroll">
                <div class="flex flex-col space-y-6 lg:flex-row lg:space-x-8 lg:space-y-0">
                  <div class="mc-field-group">
                    <Input
                      id="mce-FNAME"
                      :label="$t('firstName')"
                      type="text"
                      name="FNAME"
                      style-label="!text-white"
                      style-input="lg:w-full border border-transparent required "
                    />
                  </div>
                  <div class="relative flex flex-1 items-end">
                    <div class="mc-field-group relative flex-1">
                      <Input
                        id="mce-EMAIL"
                        :label="$t('email')"
                        type="email"
                        name="EMAIL"
                        style-label="!text-white"
                        style-input="rounded-r-none border-r-0 w-full border border-transparent required !h-[48px] lg:!h-[60px]"
                      >
                        <template v-slot:right>
                          <div
                            class="absolute -right-1 top-0 text-sm font-bold text-blue lg:-right-[165px] lg:top-0"
                          >
                            <Button
                              :title="$t('send')"
                              class="!h-[48px] rounded-md rounded-l-none !border-0 border-none px-8 lg:!h-[60px] lg:px-12"
                              type-button="submit"
                            />
                          </div>
                        </template>
                      </Input>
                    </div>

                    <div id="mce-responses" class="clear absolute top-[90px] lg:top-28">
                      <div
                        id="mce-error-response"
                        class="response font-sans text-xs font-bold text-white lg:text-sm"
                        style="display: none"
                      ></div>
                      <div
                        id="mce-success-response"
                        class="response font-sans text-white"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                </div>

                <!-- Visually hide these -->
                <div class="mc-field-group input-group sr-only">
                  <strong>Nyhetsbrev </strong>
                  <ul>
                    <li>
                      <input
                        id="mce-group[3903]-3903-0"
                        type="checkbox"
                        value="2"
                        name="group[3903][2]"
                        checked
                      />
                      <label for="mce-group[3903]-3903-0">Nyhetsbrevet</label>
                    </li>
                    <li>
                      <input
                        id="mce-group[3903]-3903-1"
                        type="checkbox"
                        value="4"
                        name="group[3903][4]"
                      />
                      <label for="mce-group[3903]-3903-1">Vegobrevet</label>
                    </li>
                    <li>
                      <input
                        id="mce-group[3903]-3903-2"
                        type="checkbox"
                        value="16"
                        name="group[3903][16]"
                      />
                      <label for="mce-group[3903]-3903-2">Aktuellt i politiken</label>
                    </li>
                    <li>
                      <input
                        id="mce-group[3903]-3903-3"
                        type="checkbox"
                        value="65536"
                        name="group[3903][65536]"
                      />
                      <label for="mce-group[3903]-3903-3">Övriga nyhetsbrev</label>
                    </li>
                    <li>
                      <input
                        id="mce-group[3903]-3903-4"
                        type="checkbox"
                        value="4194304"
                        name="group[3903][4194304]"
                      />
                      <label for="mce-group[3903]-3903-4">Testamentesveckan</label>
                    </li>
                  </ul>
                  <span id="mce-group[3903]-HELPERTEXT" class="helper_text"></span>
                </div>

                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px" aria-hidden="true">
                  <input
                    type="text"
                    name="b_075aef938fc7213aacd1f4476_2576ca066f"
                    tabindex="-1"
                    value=""
                  />
                </div>
              </div>
            </form>
          </div>

          <!--End mc_embed_signup-->
        </div>
      </div>
    </Container>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { Input, Button, Container } from 'refresh-ui'

const props = defineProps({
  data: Object,
})

const otherRightString = `<span class='absolute right-5 top-3 lg:top-4 text-sm font-bold text-blue lg:text-lg'>hello</span>`

onMounted(() => {
  if (!props?.data?.newsletter_title) return

  // Add jquery
  // TODO: Refactor so we don't need it
  const jQueryScript = document.createElement('script')
  jQueryScript.src = 'https://code.jquery.com/jquery-3.3.1.slim.min.js'
  jQueryScript.type = 'text/javascript'
  jQueryScript.async = true
  jQueryScript.defer = true
  jQueryScript.onload = () => {
    // Code to run after jQueryScript is loaded and finished
    const plugin = document.createElement('script')
    plugin.innerHTML = `
        (function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='HASHKOD';ftypes[7]='text';fnames[8]='MMERGE8';ftypes[8]='date';fnames[9]='FORETAG';ftypes[9]='text'; }(jQuery));var $mcj = jQuery.noConflict(true);
      `
    plugin.async = true
    document.getElementsByTagName('body')[0].appendChild(plugin)
  }
  document.body.appendChild(jQueryScript)

  const script = document.createElement('script')
  // script.src = 'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
  script.src = '/js/mc-validate.js'
  script.async = true
  script.defer = true
  document.getElementsByTagName('body')[0].appendChild(script)

  /* const plugin = document.createElement('script');
  plugin.innerHTML = `
  (function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='HASHKOD';ftypes[7]='text';fnames[8]='MMERGE8';ftypes[8]='date';fnames[9]='FORETAG';ftypes[9]='text'; }(jQuery));var $mcj = jQuery.noConflict(true);
  `;
  plugin.async = true;
  document.getElementsByTagName('body')[0].appendChild(plugin);*/
})
</script>

<style>
#mc_embed_signup div.mce_inline_error {
  position: absolute;
  background: transparent !important;
  padding: 0 !important;
  margin-top: 9px !important;
  font-size: 12px !important;
  @media (min-width: 1024px) {
    font-size: 14px !important;
  }
}
#mc_embed_signup input.mce_inline_error {
  border: 1px solid red !important;
}
</style>
